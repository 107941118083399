import React, { useState } from "react";
import styled, { css } from "styled-components";
import { fallbackValues } from "./Checkbox.theme";
import { noop } from "../../../util/general";
import { ENTER } from "../../../constants/keyboard";
import Box from "../layouts/Box";
import Text from "../text";
import { themeComponent } from "../../../util/themeUtils";

const CheckboxContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxLabelContainer = styled.label`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

const CheckboxIcon = styled.svg`
  fill: none;
  stroke-width: 2px;
  stroke: ${({ disabled, disabledCheckColor, checkColor }) =>
    disabled
      ? css`
          ${disabledCheckColor}
        `
      : css`
          ${checkColor}
        `};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  transition: all 150ms;

  ${CheckboxIcon} {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }

  ${({
    error,
    disabled,
    checked,
    focused,
    defaultStyles,
    checkedStyles,
    focusedStyles,
    errorStyles,
    disabledStyles,
    disabledCheckedStyles
  }) =>
    error
      ? css`
          ${errorStyles} ${focused && focusedStyles}
        `
      : disabled
      ? css`
          ${checked ? disabledCheckedStyles : disabledStyles}
        `
      : checked
      ? css`
          ${checkedStyles} ${focused && focusedStyles}
        `
      : css`
          ${defaultStyles} ${focused && focusedStyles}
        `};
`;

const Checkbox = ({
  title,
  name,
  checked,
  onChange = noop,
  disabled = false,
  themeValues,
  hidden = false,
  error = false,
  checkboxMargin = "0 16px 0 0",
  extraStyles,
  textExtraStyles,
  labelledById,
  dataQa = null
}) => {
  const [focused, setFocused] = useState(false);

  const handleClick = (e, func) => {
    if (e.keyCode === ENTER) {
      func();
    }
  };

  const titleId = title ? `checkboxlabel-${name}` : undefined;
  const ariaLabelledById = labelledById ?? titleId;
  const ariaLabel = ariaLabelledById ? undefined : name;

  return (
    <Box
      padding="0"
      tabIndex="0"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={e => handleClick(e, onChange)}
      hiddenStyles={hidden}
      background={themeValues.backgroundColor}
      extraStyles={`outline: none; ${extraStyles}; margin: ${checkboxMargin};`}
    >
      <CheckboxLabelContainer data-qa={dataQa}>
        <CheckboxContainer data-qa="Checkbox">
          <HiddenCheckbox
            id={`checkbox-${name}`}
            disabled={disabled}
            name={name}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledById}
            checked={checked}
            onChange={onChange}
            tabIndex="-1"
            aria-invalid={error}
            aria-describedby={error ? `${name}-error-message` : ""}
          />
          <StyledCheckbox
            role="checkbox"
            error={error}
            disabled={disabled}
            checked={checked}
            aria-checked={checked}
            focused={focused}
            defaultStyles={themeValues.defaultStyles}
            checkedStyles={themeValues.checkedStyles}
            errorStyles={themeValues.errorStyles}
            disabledStyles={themeValues.disabledStyles}
            disabledCheckedStyles={themeValues.disabledCheckedStyles}
            focusedStyles={themeValues.focusedStyles}
          >
            <CheckboxIcon
              viewBox="0 0 24 24"
              disabled={disabled}
              disabledCheckColor={themeValues.disabledCheckColor}
              checkColor={themeValues.checkColor}
            >
              <polyline points="20 6 9 17 4 12" />
            </CheckboxIcon>
          </StyledCheckbox>
        </CheckboxContainer>
        {title && (
          <Text
            id={titleId}
            variant="p"
            weight={themeValues.textFontWeight}
            color={themeValues.textColor}
            extraStyles={
              textExtraStyles
                ? `${textExtraStyles} ${disabled &&
                    `color: #6e727e; background-color: #f7f7f7;`} `
                : `margin-left: 1rem ${disabled &&
                    `color: #6e727e; background-color: #f7f7f7;`}`
            }
          >
            {title}
          </Text>
        )}
      </CheckboxLabelContainer>
    </Box>
  );
};

export default themeComponent(Checkbox, "Checkbox", fallbackValues, "default");
