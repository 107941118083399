import React from "react";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import { DetailText } from "./Detail.styled";
import { safeChildren } from "../../../util/general";
import {
  fallbackValues,
  mobileFallbackValues,
  MOBILE_BREAKPOINT
} from "./Detail.theme";
import { themeComponent } from "../../../util/themeUtils";

/*
  New responsive text component for Detail elements
  (Block level text elements which are smaller than "Title" elements)
  Size is decoupled from tag
  Comes with four variants - "large", "regular", "small", "extraSmall"
  Large variant results in text that is 1.125rem/1.1428rem (18px desktop, 16px mobile)
  Regular variant results in text that is 1rem (16px desktop, 14px mobile)
  Small variant reuslts in text that is 0.875rem/0.8571rem (14px desktop, 12px mobile)
  ExtraSmall variant results in text that is 0.75rem/0.7142rem (12px desktop, 10px mobile)
  
  If you need a larger font size, use the <Title /> atom, which has larger variants
  
  Use the "as" prop to specify element type, can use any of: h1/h2/h3/h4/h5/h6/p
  If you need an inline text element (span), use the <Text /> atom
  The "as" value should be dictated by the structure of your page, not what font-size you want

  Title / Detail both use slightly different desktop/mobile scales
  (Mobile scale is different to allow for design-friendly px values)
  Both atoms detect the presence of a mobile device on their own
  and apply the corresponding scale

  Mobile breakpoint value is specified in Detail.theme.js

  If you want to disable mobile text scales (use the same rem scale for desktop/mobile), then use a theme in FCS to set all of the variants to use the same rem sizes. FCS themes override fallbacks defined in the .theme file
*/

const isBelowBreakpoint = window.innerWidth < MOBILE_BREAKPOINT;
const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 1;
const mobileDeviceDetected = isBelowBreakpoint && isTouchDevice;
const fallbacks = mobileDeviceDetected ? mobileFallbackValues : fallbackValues;

const Detail = ({
  themeValues,
  weight = FONT_WEIGHT_REGULAR,
  color = FIREFLY_GREY,
  margin = 0,
  textAlign,
  extraStyles = ``,
  className,
  variant = "regular",
  as = "p",
  dataQa,
  children,
  ...rest
}) => {
  return (
    <DetailText
      variant={variant}
      as={as}
      weight={weight}
      color={color}
      margin={margin}
      textAlign={textAlign}
      extraStyles={extraStyles}
      className={className}
      fontFamily={themeValues.fontFamily}
      fontSize={themeValues.fontSize}
      data-qa={dataQa}
      {...rest}
    >
      {safeChildren(children, <span />)}
    </DetailText>
  );
};

export default themeComponent(Detail, "Detail", fallbacks, "regular");
