/* eslint-disable */
import { ofType } from "redux-observable";
import request from "graphql-request";
import {
  map,
  mapTo,
  filter,
  debounce,
  flatMap,
  mergeMapTo,
  concat,
  ignoreElements,
  catchError
} from "rxjs/operators";
import { timer, of, merge, throwError, from, identity, EMPTY } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";
import actions, {
  clearBoundData,
  resetWorkflowState,
  setWorkflowPageName
} from "../../renderer/actions";
import { v4 as uuidv4 } from "uuid";
import { moneyStringToFloat } from "../../util/general";

const _getAllComponents = components => currentComponent =>
  R.ifElse(
    R.has("components"),
    c => {
      const childrenComponents = R.chain(
        _getAllComponents([]),
        R.prop("components", c)
      );
      return R.pipe(
        R.append(R.__, components),
        R.concat(childrenComponents)
      )(c);
    },
    R.append(R.__, components)
  )(currentComponent);

const getAllComponents = _getAllComponents([]);

const isDependentOnId = R.curry(
  (id, component) => R.path(["options", "depends", "id"], component) === id
);

const normalizeDependentValue = dependentValue => {
  if (dependentValue && dependentValue.valueList) {
    return dependentValue.valueList;
  }
  if (!Array.isArray(dependentValue)) {
    return [dependentValue];
  }
  return dependentValue;
};

export const resetDependentBoundDataEpic = (action$, state$) =>
  action$.ofType(actions.COMPONENT_CHANGED).pipe(
    flatMap(({ id, value }) =>
      R.pipe(
        R.always(
          R.chain(
            getAllComponents,
            R.map(R.prop("data"), state$.value.workflow.workflow.nodes)
          )
        ),
        R.filter(
          R.pipe(
            R.propOr({}, "options"),
            R.and(R.has("depends"), R.has("bind"))
          )
        ),
        R.filter(isDependentOnId(id)),
        R.filter(c => {
          return !R.contains(
            R.path(["options", "depends", "value"], c),
            normalizeDependentValue(value)
          );
        }),
        R.map(clearBoundData)
      )()
    )
  );

/**
 *
 */
const handleGraphQLError = errors => {
  if (errors) {
    throw new Error(R.map(errors, R.prop("message")).join(" "));
  }
};

const doSubmitACHPaymentMutation = async (achPaymentInput, graphqlEndpoint) => {
  const response = await request(
    graphqlEndpoint,
    `
      mutation submitACHPayment($input: SubmitACHPaymentInput!) {
        submitACHPayment(input: $input) {
          payment {
            transactionId
          }
        }
      }
    `,
    { input: achPaymentInput }
  );
  handleGraphQLError(response.errors);
  return response.submitACHPayment.payment.transactionId;
};

const doSubmitCardPaymentMutation = async (
  cardPaymentInput,
  graphqlEndpoint
) => {
  const response = await request(
    graphqlEndpoint,
    `
      mutation submitCardPayment($input: SubmitCardPaymentInput!) {
        submitCardPayment(input: $input) {
          payment {
            transactionId
          }
        }
      }
    `,
    { input: cardPaymentInput }
  );
  handleGraphQLError(response.errors);
  return response.submitCardPayment.payment.transactionId;
};

const doCreatePaymentMutation = async (paymentInput, graphqlEndpoint) => {
  const response = await request(
    graphqlEndpoint,
    `
      mutation createPayment($input: CreatePaymentInput!) {
        createPayment(input: $input) {
          payment {
            id
            serviceFees {
              amount
              kind
            }
          }
        }
      }
    `,
    { input: paymentInput }
  );
  handleGraphQLError(response.errors);
  return response.createPayment;
};

const iterateComponents = item => {
  if (Array.isArray(item.components)) {
    return item.components.map(component => {
      if ("components" in component) {
        const components = iterateComponents(component);
        if (R.path(["options", "include_in_store"], item)) {
          components.push(item);
        }
        return components;
      }
      return component;
    });
  } else {
    return [];
  }
};

const createWorkflowInitialState = R.ifElse(
  result => R.path(["graph_document", "config", "initial_state"], result),
  result =>
    R.reduce(
      R.mergeDeepLeft,
      {},
      R.map(initialStateItem => {
        return R.assocPath(
          initialStateItem.location,
          initialStateItem.value,
          result
        );
      }, R.path(["graph_document", "config", "initial_state"], result))
    ),
  result => result
);

// I cannot be held responsible for this -Liam
const populateWorkflowStateKeys = result => {
  const json = {};
  json.workflow = { graph_document: result.graph_document };
  json.title = result.title;
  json.slug = result.slug;
  json.client = result.client;
  json.sub_client = result.sub_client;
  json.userinput = result.graph_document.nodes.map(function flattenComponents(
    screen
  ) {
    const allComponents = iterateComponents(screen.data);
    return R.flatten(allComponents).reduce(function makeData(obj, component) {
      obj[component.id] = component;
      return obj;
    }, {});
  });
  return json;
};

const getPathFromRouterState = state => {
  const firstLocationPath = state?.value?.router?.location?.pathname ?? "";
  const locationPath = state?.value?.router?.location?.location?.pathname ?? "";

  return firstLocationPath || locationPath;
};

const filterBySlug = val =>
  R.filter(R.compose(R.any(R.contains(val)), R.values));

const getActiveWorkflow = (state, slug) =>
  R.find(R.propEq("slug", slug))(
    filterBySlug(slug)([
      ...state.value.global.workflows.data.default,
      ...state.value.global.workflows.data.obligationAssociation
    ])
  );

export const workflowInitialStateEpic = (action$, state$) =>
  action$.ofType("FETCH_WORKFLOW_SUCCESS", "@@router/LOCATION_CHANGE").pipe(
    // want to only load workflow if you are at the /service/ route
    // and if the workflow has not been loaded.
    filter(
      () =>
        isServiceRoute(getPathFromRouterState(state$)) && workflowsBuilt(state$)
    ),
    debounce(() => timer(500)),
    flatMap(() => {
      const workflow = getActiveWorkflow(
        state$,
        getServiceSlugFromPath(getPathFromRouterState(state$))
      );
      return workflow
        ? of(populateWorkflowStateKeys(workflow)).pipe(
            map(createWorkflowInitialState),
            flatMap(initialState =>
              of(
                {
                  type: "SET_ACTIVE_WORKFLOW",
                  payload: {
                    status: "received",
                    title: initialState.title,
                    slug: initialState.slug,
                    client: initialState.client,
                    sub_client: initialState.sub_client
                  }
                },
                {
                  type: "INITIAL_WORKFLOW_RECEIVED",
                  payload: { workflow: initialState.workflow.graph_document }
                },
                {
                  type: "INITIAL_USER_INPUT_RECEIVED",
                  payload: { userinput: initialState.userinput }
                }
              )
            )
          )
        : of({ type: "WORKFLOW_NOT_FOUND" });
    })
  );

const isServiceRoute = path => {
  return R.match(/\/service\/.+/g, path).length > 0;
};

const isNotServiceRoute = R.complement(isServiceRoute);

// we don't want to reset the workflow state if the user is on the shopping cart page
// unlike the rest of checkout, the cart page is at /cart not /service/{serviceName}/cart
const isCartRoute = path => {
  return R.match(/\/cart/g, path).length > 0;
};

const isNotCartRoute = R.complement(isCartRoute);

const getServiceSlugFromPath = path => {
  if (isServiceRoute(path)) {
    return /service\/([\w,-]+)\/?[.+]?/g.exec(path)[1];
  }
  return null;
};

const getPathFromLocationAction = locationAction => {
  const initialLocation = locationAction?.payload?.location?.pathname ?? "";
  const location = locationAction?.payload?.location?.location?.pathname ?? "";
  return initialLocation !== "" ? initialLocation : location;
};

const isWorkflowNotAsked = state =>
  R.pathEq(["value", "workflow", "workflow", "stage"], "not-asked")(state);

const isWorkflowDoneLoading = state =>
  R.pathEq(["value", "global", "workflows", "phase"], "SUCCESS")(state);

const workflowsBuilt = state =>
  isWorkflowNotAsked(state) && isWorkflowDoneLoading(state);

export const workflowRouterResetStateRoutingEpic = action$ =>
  action$.ofType("@@router/LOCATION_CHANGE").pipe(
    RxOp.flatMap(action => {
      const locationPath = getPathFromLocationAction(action);
      return isNotServiceRoute(locationPath) && isNotCartRoute(locationPath)
        ? of(resetWorkflowState())
        : EMPTY;
    })
  );

export const workflowRouterChangeScreenEpic = (action$, store) =>
  action$.ofType("@@router/LOCATION_CHANGE").pipe(
    filter(R.pipe(getPathFromLocationAction, isServiceRoute)),
    map(action =>
      R.pipe(
        getPathFromLocationAction,
        R.split("/"),
        R.last(),
        R.propEq("name"),
        R.findIndex(
          R.__,
          R.pathOr([], ["value", "workflow", "workflow", "nodes"], store)
        ),
        R.ifElse(R.equals(-1), R.always(0), R.identity)
      )(action)
    ),
    map(screenIndex => ({
      type: actions.CHANGE_SCREEN,
      screenIndex,
      isBack:
        screenIndex ===
        R.last(store.value.workflow.currentScreen.screenIndexHistory)
    }))
  );

const createWorkflowPageTitle = (action, state) => {
  const formatNameComponent = component =>
    component !== "" ? `${component} | ` : "";
  const currentWorkflowScreenIndex = action?.screenIndex ?? 0;
  const workflowScreenGroupName =
    state?.workflow?.workflow?.nodes?.[currentWorkflowScreenIndex]?.data
      ?.components?.[0]?.options?.title ?? "";
  const workflowName = state?.workflow?.workflowMetadata?.title ?? "";
  const clientName =
    state?.global?.clientMetadata?.data?.clientDisplayName ?? "";

  const joinedName = `${formatNameComponent(
    workflowScreenGroupName
  )}${formatNameComponent(workflowName)}${clientName}`;

  return joinedName;
};

export const updateWorkflowPageTitleEpic = (action$, state$) =>
  action$
    .ofType("INITIAL_WORKFLOW_RECEIVED", "CHANGE_SCREEN")
    .pipe(
      RxOp.flatMap(action =>
        of(createWorkflowPageTitle(action, state$.value)).pipe(
          RxOp.mergeMap(workflowPageName =>
            of(setWorkflowPageName(workflowPageName))
          )
        )
      )
    );

const createActionOnScreenChangeEpic = (pageName, action) => (
  action$,
  state$
) =>
  action$.ofType("CHANGE_SCREEN").pipe(
    filter(
      action =>
        R.pathOr(
          "",
          [
            "value",
            "workflow",
            "workflow",
            "nodes",
            action.screenIndex,
            "name"
          ],
          state$
        ) === pageName
    ),
    mapTo(action())
  );

/*
  input PayableItemInput {
    # A unique identifier to distinguish one payable item from another.
    # For some payment types, this could be an account number or an invoice number, or even a UPC.
    # In cases where there is not a natural identifier, clients are encouraged to generate a random value.
    id: ID!
    name: String
    quantity: Int!
    amount: Currency!
    customAttributes: [AttributeInput!]
  }
  */
const formatInputStateForCreatePayment = state =>
  R.pipe(
    R.path(["boundData", "paymentItems"]),
    R.filter(R.prop("amount")),
    R.mapObjIndexed((val, key, obj) => ({
      id: uuidv4(),
      name: key,
      amount: R.pipe(R.prop("amount"), moneyStringToFloat, s => s.toString())(
        val
      ),
      quantity: R.pathOr(1, [key, "quantity"], obj),
      customAttributes: R.pipe(
        R.propOr({}, "customAttributes"),
        R.mapObjIndexed((caVal, caKey, caObj) => ({
          kind: caKey,
          data: caVal
        })),
        R.values
      )(val)
    })),
    R.values,
    items => ({
      client: R.path(["boundData", "client"], state),
      items
    })
  )(state);

const paymentPath = key => keyTwo => [
  "workflow",
  "boundData",
  "payment",
  key,
  keyTwo
];

const billingInfoPath = paymentPath("billing");
const creditCardPath = paymentPath("credit_card");
const achPath = paymentPath("check");
const contactPath = paymentPath("contact");

const formatBillingInformation = state => {
  const getFromState = R.compose(R.path(R.__, state), billingInfoPath);
  return {
    address1: getFromState("address_1"),
    address2: getFromState("address_2"),
    city: getFromState("city"),
    state: getFromState("state"),
    zipCode: getFromState("zip_code"),
    firstName: getFromState("first_name"),
    lastName: getFromState("last_name")
  };
};

const formatInputStateForSubmitCardPayment = state => {
  const getFromState = R.compose(R.path(R.__, state), creditCardPath);
  return {
    id: R.path(["workflow", "intermediaryState", "paymentId"], state),
    client: R.path(["workflow", "boundData", "client"], state),
    items: R.path(
      ["workflow", "intermediaryState", "paymentItems", "items"],
      state
    ),
    billing: formatBillingInformation(state),
    emailAddress: R.path(contactPath("email_address"), state),
    cardInfo: {
      cardNumber: getFromState("card_number"),
      cvv: getFromState("cvv"),
      expirationMonth: getFromState("exp_month"),
      expirationYear: getFromState("exp_year")
    },
    firstName: R.path(billingInfoPath("first_name"), state),
    lastName: R.path(billingInfoPath("last_name"), state),
    serviceFee: R.find(
      R.propEq("kind", "CREDIT_CARD"),
      R.path(["workflow", "intermediaryState", "serviceFees"], state)
    )
  };
};

const isCheckPaymentType = R.pathEq(
  ["workflow", "boundData", "payment", "payment_type"],
  "check"
);

const isCardPaymentType = R.pathEq(
  ["workflow", "boundData", "payment", "payment_type"],
  "credit_card"
);

const getCheckType = R.cond([
  [s => s.includes("personal"), R.always("PERSONAL")],
  [s => s.includes("business"), R.always("BUSINESS")],
  [R.T, R.always("OTHER")]
]);

const getAccountType = R.cond([
  [s => s.includes("savings"), R.always("SAVINGS")],
  [s => s.includes("checking"), R.always("CHECKING")],
  [R.T, R.always("OTHER")]
]);

const getTenderType = R.cond([
  [isCheckPaymentType, R.always("CHECK")],
  [isCardPaymentType, R.always("CREDIT_CARD")],
  [R.T, R.always("ERROR")]
]);

const formatInputStateForSubmitACHPayment = state => {
  const getFromState = R.compose(R.path(R.__, state), achPath);
  return {
    id: R.path(["workflow", "intermediaryState", "paymentId"], state),
    emailAddress: R.path(contactPath("email_address"), state),
    billing: formatBillingInformation(state),
    achInfo: {
      accountNumber: getFromState("bank_account_number"),
      accountType: getAccountType(getFromState("bank_account_type")),
      checkType: getCheckType(getFromState("bank_account_type")),
      routingNumber: getFromState("bank_routing_number")
    },
    client: R.path(["workflow", "boundData", "client"], state),
    firstName: R.path(billingInfoPath("first_name"), state),
    lastName: R.path(billingInfoPath("last_name"), state),
    items: R.path(
      ["workflow", "intermediaryState", "paymentItems", "items"],
      state
    ),
    serviceFee: R.pipe(
      R.path(["workflow", "intermediaryState", "serviceFees"]),
      R.find(R.propEq("kind", "CREDIT_CARD")),
      R.assoc("kind", getTenderType(state))
    )(state)
  };
};
