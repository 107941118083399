import React from "react";
import RadioButtonWithLabel from "../../atoms/radio-button-with-label/RadioButtonWithLabel";
import { Stack } from "../../atoms";
import { colors } from "../../../constants";
import styled from "styled-components";
import { noop } from "../../../util/general";

const DefaultHeading = styled.div`
  font-size: 0.875rem;
  color: ${colors.CHARADE_GREY}
  margin: 0;
  padding: 8px 0px;
`;

const StyledFieldset = styled.fieldset`
  ${props => props.$extraStyles}
`;

const RadioGroup = ({
  headingText,
  groupName,
  heading: Heading = (
    <DefaultHeading role="heading" id={`radio-group-${groupName}-heading`}>
      {headingText}
    </DefaultHeading>
  ),
  config,
  extraStyles,
  handleChange = noop, // optional, for custom event handling in ingesting app
  // redux-freeform props - this is similar to how FormInput works, duplicated because the radio input is hidden for styling overrides
  field,
  fieldActions
}) => {
  const setValue = value => fieldActions.set(value);
  return (
    <StyledFieldset
      role="radiogroup"
      aria-labelledby={`radio-group-${groupName}-heading`}
      $extraStyles={extraStyles}
    >
      {Heading !== null && Heading}
      <Stack childGap="4px">
        {config.map((c, idx) => (
          <RadioButtonWithLabel
            index={idx}
            key={c.id}
            {...c}
            groupName={groupName}
            setValue={setValue}
            handleChange={handleChange}
            field={field}
            config={config}
            aria-invalid={field.dirty && field.hasErrors}
          />
        ))}
      </Stack>
    </StyledFieldset>
  );
};

export default RadioGroup;
