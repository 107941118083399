import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as L from "partial.lenses";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Breadcrumb,
  ButtonWithAction,
  PhoneForm,
  Module,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import {
  PHONE_SETTING,
  PHONE_RESOURCE,
  SUBMIT_OPERATIONS
} from "../../Profile.state";
import S from "/util/Sanctuary";
import AlertBar from "/components/alert-bar";

const AddPhone = ({
  requests,
  actions,
  forms,
  resourcesActions,
  resources,
  alertBars,
  handleFocusErrors
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const phoneID = params?.id ?? "";
  const action = params.id ? SUBMIT_OPERATIONS.UPDATE : SUBMIT_OPERATIONS.ADD;
  const requestStatusPhone = requests[PHONE_SETTING] || S.RemoteData.NotAsked;
  const {
    forms: { phoneForm: formActions },
    alertBars: { phoneAlertBar: alertBarActions }
  } = actions;
  const { phoneForm: form } = forms;
  const { onSubmitChange } = resourcesActions;
  const { phoneAlertBar: alertBar } = alertBars;
  const phone = L.get(
    L.compose(L.prop(PHONE_RESOURCE), L.prop(phoneID)),
    resources
  );
  const [errors, handleErrors] = useState(false);
  const { isMobile } = useContext(ThemeContext);
  if (action === SUBMIT_OPERATIONS.UPDATE) {
    useEffect(() => {
      formActions.actions.fields.phone.set(phone.value);
      return () => {};
    }, [phoneID]);
  }

  const handleSubmit = util.general.generateClickHandler(
    form,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => onSubmitChange(PHONE_SETTING, action, phone ? phone.id : "")
  );
  const actionText =
    action === SUBMIT_OPERATIONS.ADD ? "Add Phone Number" : "Edit Phone Number";

  return (
    <Box padding="0 0 16px" width={isMobile ? "100%" : "576px"}>
      <Breadcrumb
        breadcrumbsList={[
          {
            linkText: "Wallet",
            linkDestination: "/profile/wallet",
            isActive: false
          },
          {
            linkText: actionText,
            linkDestination: "#",
            isActive: true
          }
        ]}
      />
      {alertBar.alerts.length > 0 && (
        <Box padding="1rem 0 0">
          <AlertBar {...alertBar} {...alertBarActions} />
        </Box>
      )}
      <Module heading={actionText}>
        <PhoneForm
          clearOnDismount
          {...form}
          {...formActions}
          showErrors={errors}
          handleSubmit={handleSubmit}
        />
      </Module>
      <Box
        padding="1.5rem 0 0 0"
        borderColor={constants.colors.GHOST_GREY}
        borderSize="1px"
        borderWidthOverride="1px 0 0 0"
      >
        {isMobile ? (
          <Stack childGap="1rem" direction="row">
            <Box width="100%" padding="0">
              <ButtonWithAction
                text="Cancel"
                action={() => navigate(-1)}
                variant={"secondary"}
                dataQa="Cancel"
                extraStyles={`width: 100%;`}
              />
            </Box>
            <Box width="100%" padding="0">
              <ButtonWithAction
                text="Save"
                action={handleSubmit}
                isLoading={requestStatusPhone.isLoading}
                variant="primary"
                dataQa="Save"
                extraStyles={`width: 100%;`}
              />
            </Box>
          </Stack>
        ) : (
          <Stack childGap="1rem" direction="row" justify="flex-end">
            <ButtonWithAction
              text="Cancel"
              action={() => navigate(-1)}
              variant={"secondary"}
              dataQa="Cancel"
            />
            <ButtonWithAction
              text="Save"
              action={handleSubmit}
              isLoading={requestStatusPhone.isLoading}
              variant="primary"
              dataQa="Save"
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default withWindowSize(AddPhone);
