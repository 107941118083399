import React, { useEffect } from "react";
import { required, isProbablyEmail } from "redux-freeform";
import {
  FormInput,
  FormContainer,
  FormInputColumn
} from "../../atoms/form-layouts";
import Checkbox from "../../atoms/checkbox";
import Paragraph from "../../atoms/paragraph";
import { noop } from "../../../util/general";

const EmailForm = ({
  variant = "default",
  clearOnDismount,
  fields,
  actions,
  showErrors,
  guestCheckout,
  handleSubmit = noop,
  showWalletCheckbox,
  saveToWallet,
  walletCheckboxMarked,
  isRequired = false
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const emailFieldErrorMessages = {
    [required.error]: "Email address is required",
    [isProbablyEmail.error]: "Invalid email address"
  };

  return (
    <FormContainer variant={variant} role="form" aria-label="Email address">
      {guestCheckout && (
        <Paragraph margin="0 0 1.125rem 0">
          If desired, you can create a wallet later for faster checkout
        </Paragraph>
      )}
      <FormInputColumn>
        <FormInput
          labelTextWhenNoError="Email address"
          errorMessages={emailFieldErrorMessages}
          type="email"
          field={fields.email}
          fieldActions={actions.fields.email}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          isEmail
          autocompleteValue="email"
          dataQa="Email address"
          isRequired={isRequired}
        />
        {showWalletCheckbox && (
          <Checkbox
            name="email checkbox"
            title="Save email address to wallet"
            checked={walletCheckboxMarked}
            onChange={saveToWallet}
          />
        )}
      </FormInputColumn>
    </FormContainer>
  );
};

export default EmailForm;
