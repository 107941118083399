import React from "react";

import { themeComponent } from "../../../util/themeUtils";
import withWindowSize from "../../withWindowSize";

import { fallbackValues } from "./Card.theme";

import Box from "../layouts/Box";
import BoxWithShadow from "../box-with-shadow";
import Center from "../layouts/Center";
import Cover from "../layouts/Cover";
import Stack from "../layouts/Stack";

import CardImage from "./CardImage.styled";
import CardText from "./CardText";
import CardHeader from "./CardHeader";

const Card = ({
  borderRadius = "4px",
  extraStyles,
  headerAs = "h2",
  headerText,
  headerVariant = "small",
  imgSrc,
  Image,
  imgHeight = "150px",
  imgObjectFit = "none",
  imgAltText,
  padding = "24px",
  text,
  titleText,
  titleVariant = "small",
  themeValues,
  width = "276px",
  children
}) => {
  const hasImage = Image || imgSrc;
  const numberOfChildren =
    (Array.isArray(children) ? children.length : 1) +
    (text ? 1 : 0) +
    (hasImage ? 1 : 0) +
    (headerText ? 1 : 0);

  return (
    <BoxWithShadow
      variant="baseStandard"
      background={themeValues.backgroundColor}
      borderRadius={borderRadius}
      padding="0"
      margin="0"
      minWidth={width}
      extraStyles={extraStyles}
    >
      <Cover singleChild>
        <Stack fullHeight childGap="1.5rem" bottomItem={numberOfChildren}>
          {headerText && (
            <CardHeader
              headerText={headerText}
              headerColor={themeValues.headerColor}
              headerVariant={headerVariant}
              backgroundColor={themeValues.headerBackgroundColor}
              borderRadius={borderRadius}
              padding={padding}
              as={headerAs}
            ></CardHeader>
          )}
          <Stack direction="row" childGap="0">
            {Image && !imgSrc && (
              <Box
                minHeight={imgHeight}
                padding="0"
                background={themeValues.imageBackgroundColor}
              >
                <Cover minHeight={imgHeight} singleChild>
                  <Center intrinsic>
                    <Image alt={imgAltText} />
                  </Center>
                </Cover>
              </Box>
            )}
            {imgSrc && (
              <CardImage
                height={imgHeight}
                objectFit={imgObjectFit}
                backgroundColor={themeValues.imageBackgroundColor}
                src={imgSrc}
                alt={imgAltText}
              />
            )}
            <Box padding="0" width="100%" extraStyles="flex-basis: 100%;">
              {text && (
                <CardText
                  padding={padding}
                  titleText={titleText}
                  text={text}
                  titleVariant={titleVariant}
                />
              )}
              {children}
            </Box>
          </Stack>
        </Stack>
      </Cover>
    </BoxWithShadow>
  );
};

export default themeComponent(withWindowSize(Card), "Card", fallbackValues);
