import React, { useState } from "react";
import styled from "styled-components";
import { format } from "formatted-input";
import {
  Module,
  AddressForm,
  EmailForm,
  PhoneForm,
  FormattedAddress,
  Title,
  Box,
  PaymentButtonBar,
  RadioSection,
  Cluster,
  util,
  constants
} from "@thecb/components";
import {
  EMAIL_TITLE,
  ADDRESS_TITLE,
  PHONE_TITLE
} from "../../../../../../constants/forms";

const PaymentCustomerInformationContainer = styled.div``;

const PaymentCustomerInformation = ({
  newAddressSelected,
  selectedSavedAddress,
  selectNewAddress,
  selectSavedAddress,
  newEmailSelected,
  selectedSavedEmail,
  selectSavedEmail,
  selectNewEmail,
  newPhoneSelected,
  selectedSavedPhone,
  selectSavedPhone,
  selectNewPhone,
  addressForm,
  addressFormActions,
  emailForm,
  emailFormActions,
  phoneForm,
  phoneFormActions,
  serviceName,
  navigate,
  goToNextPage,
  savedAddresses,
  savedContactEmails,
  savedContactPhone,
  isLoggedIn,
  isMobile,
  walletEnabled,
  wallet,
  saveToWallet,
  handleFocusErrors,
  stepCounter,
  walletScreenV2Enabled,
  usesCustomerInformationEmail
}) => {
  const [errors, handleErrors] = useState(false);
  const isNewDataEntry =
    newAddressSelected || newEmailSelected || newPhoneSelected;
  const masterForm = {
    fields: {
      ...(newAddressSelected && { ...addressForm.fields }),
      ...((usesCustomerInformationEmail || !walletScreenV2Enabled) && {
        ...emailForm.fields
      }),
      ...(newPhoneSelected && { ...phoneForm.fields })
    }
  };
  const handleSubmit = isNewDataEntry
    ? util.general.generateClickHandler(
        masterForm,
        () => {
          handleErrors(true);
          handleFocusErrors(true);
        },
        () => goToNextPage(serviceName)
      )
    : () => goToNextPage(serviceName);

  const formatPhone = format(util.formats.phoneFormat);
  const savedBillingAddresses =
    isLoggedIn && savedAddresses && Object.keys(savedAddresses).length > 0
      ? Object.values(savedAddresses).map(address => ({
          id: address.id,
          title: (
            <FormattedAddress
              {...address}
              isActive={address.id === selectedSavedAddress.addressId}
              variant="radio"
              isRadio
            />
          ),
          dataQa: "saved-address"
        }))
      : [];
  const savedPhoneNumbers =
    isLoggedIn && savedContactPhone && Object.keys(savedContactPhone).length > 0
      ? Object.values(savedContactPhone).map(item => ({
          id: item.id,
          title: formatPhone(item.value),
          dataQa: "saved-phone"
        }))
      : [];
  const savedEmails =
    isLoggedIn &&
    savedContactEmails &&
    Object.keys(savedContactEmails).length > 0
      ? Object.values(savedContactEmails).map(item => ({
          id: item.id,
          title: formatPhone(item.value),
          dataQa: "saved-emails"
        }))
      : [];

  const toggleAddress = id =>
    savedAddresses && id in savedAddresses
      ? selectSavedAddress(id)
      : selectNewAddress();

  const togglePhone = id =>
    savedContactPhone && id in savedContactPhone
      ? selectSavedPhone(id)
      : selectNewPhone();

  const toggleEmail = id =>
    savedContactEmails && id in savedContactEmails
      ? selectSavedEmail(id)
      : selectNewEmail();

  /**
   * computeFormHasErrors returns boolean indicating if form has error
   * @param {object} form
   * @returns {boolean}
   */
  const computeFormHasErrors = form =>
    Object.values(form.fields).reduce(
      (acc, curr) => acc || curr.hasErrors,
      false
    );

  return (
    <PaymentCustomerInformationContainer>
      <Cluster justify="space-between" align="center" nowrap>
        <Box
          padding={isMobile ? "0 0 1rem 0" : "1rem 0"}
          width={isMobile ? "60%" : "100%"}
        >
          <Title
            weight={constants.fontWeights.FONT_WEIGHT_BOLD}
            extraStyles={`font-size: 1.75rem;`}
            id="checkout-section-title"
            as="h1"
          >
            Customer Information
          </Title>
        </Box>
        {isMobile && (
          <Box padding={isMobile ? "0 0 1rem 0" : "1rem 0"} width={"40%"}>
            {stepCounter}
          </Box>
        )}
      </Cluster>
      <Module
        heading="Billing Information"
        spacing="0"
        padding={isMobile ? "1rem" : "1.5rem"}
        spacingBottom="1.5rem"
        as="h2"
      >
        {savedBillingAddresses.length >= 1 ? (
          <RadioSection
            sections={savedBillingAddresses.concat([
              {
                id: ADDRESS_TITLE,
                title: ADDRESS_TITLE,
                content: (
                  <AddressForm
                    variant="checkout"
                    {...addressForm}
                    {...addressFormActions}
                    showErrors={errors}
                  />
                )
              }
            ])}
            toggleOpenSection={id => toggleAddress(id)}
            openSection={selectedSavedAddress.addressId || ADDRESS_TITLE}
            staggeredAnimation
            isMobile={isMobile}
          />
        ) : (
          <Box
            padding="0"
            borderSize="1px"
            borderColor={constants.colors.GHOST_GREY}
            borderRadius="4px"
          >
            <AddressForm
              variant="checkout"
              {...addressForm}
              {...addressFormActions}
              showErrors={errors}
              showWalletCheckbox={walletEnabled && isLoggedIn}
              saveToWallet={() =>
                saveToWallet({
                  address: !wallet.savedWalletData.address
                })
              }
              walletCheckboxMarked={wallet.savedWalletData.address}
            />
          </Box>
        )}
      </Module>
      <Module
        heading="Contact Phone Number"
        padding={isMobile ? "1rem" : "1.5rem"}
        spacing="0"
        spacingBottom="1.5rem"
        as="h2"
      >
        {savedPhoneNumbers.length >= 1 ? (
          <RadioSection
            sections={savedPhoneNumbers.concat({
              id: PHONE_TITLE,
              title: PHONE_TITLE,
              content: (
                <PhoneForm
                  variant="checkout"
                  {...phoneForm}
                  {...phoneFormActions}
                  showErrors={errors}
                  isRequired={true}
                />
              )
            })}
            toggleOpenSection={id => togglePhone(id)}
            openSection={selectedSavedPhone.contactId || PHONE_TITLE}
            staggeredAnimation
            isMobile={isMobile}
          />
        ) : (
          <Box
            padding="0"
            borderSize="1px"
            borderColor={constants.colors.GHOST_GREY}
            borderRadius="4px"
          >
            <PhoneForm
              variant="checkout"
              {...phoneForm}
              {...phoneFormActions}
              showErrors={errors}
              showWalletCheckbox={walletEnabled && isLoggedIn}
              saveToWallet={() =>
                saveToWallet({
                  phone: !wallet.savedWalletData.phone
                })
              }
              walletCheckboxMarked={wallet.savedWalletData.phone}
              isRequired={true}
            />
          </Box>
        )}
      </Module>
      {(!walletScreenV2Enabled || usesCustomerInformationEmail) && (
        <Module
          heading="Contact Email Address"
          padding={isMobile ? "1rem" : "1.5rem"}
          spacing="0"
          as="h2"
        >
          {savedEmails.length >= 1 ? (
            <RadioSection
              sections={savedEmails.concat({
                id: EMAIL_TITLE,
                title: EMAIL_TITLE,
                content: (
                  <EmailForm
                    variant="checkout"
                    {...emailForm}
                    {...emailFormActions}
                    showErrors={errors}
                    isRequired={true}
                  />
                )
              })}
              toggleOpenSection={id => toggleEmail(id)}
              openSection={selectedSavedEmail.contactId || EMAIL_TITLE}
              staggeredAnimation
              isMobile={isMobile}
            />
          ) : (
            <Box
              padding="0"
              borderSize="1px"
              borderColor={constants.colors.GHOST_GREY}
              borderRadius="4px"
            >
              <EmailForm
                variant="checkout"
                {...emailForm}
                {...emailFormActions}
                showErrors={errors}
                showWalletCheckbox={walletEnabled && isLoggedIn}
                saveToWallet={() =>
                  saveToWallet({
                    email: !wallet.savedWalletData.email
                  })
                }
                walletCheckboxMarked={wallet.savedWalletData.email}
                isRequired={true}
              />
            </Box>
          )}
        </Module>
      )}
      <PaymentButtonBar
        nextButtonTestId="Next"
        backButtonTestId="Back"
        backButtonAction={() => navigate(-1)}
        forwardButtonAction={handleSubmit}
        forwardButtonAriaRole="link"
        forwardButtonText={walletScreenV2Enabled ? "Next: Review" : "Next"}
        isForwardButtonDisabled={
          !selectedSavedEmail?.contactId && computeFormHasErrors(masterForm)
        }
      />
    </PaymentCustomerInformationContainer>
  );
};

export default React.memo(PaymentCustomerInformation);
