import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import { get, slice } from "partial.lenses";
import { matchRoutes } from "react-router";
import { state as profileState } from "../../../profile/pages/user-profile";
import {
  SUBMIT_PAYMENT,
  CREATE_PAYMENT,
  submitPaymentSuccess,
  submitPaymentDuplicate,
  submitPaymentFailure,
  FETCH_INVOICE,
  navigateToCheckoutSubpage,
  createPayment,
  fetchInvoiceSuccess,
  fetchInvoiceFailure,
  CREATE_PAYMENT_FROM_LOCAL_STORAGE,
  receiveProfileResources,
  sendWalletResources,
  SEND_WALLET_RESOURCES,
  INITIATE_EXPIRED_SESSION,
  setExpiredURL,
  expirePaymentSession,
  clearProcessedPaymentInfo,
  PAYMENT_INITIATOR_WEB_CHECKOUT,
  PAYMENT_INITIATOR_PROFILE,
  PAYMENT_INITIATOR_WALLET,
  GET_VISIT_ID,
  visitIdSuccess,
  visitIdFailure,
  PAYMENT_AMOUNT_LOADING,
  PAYMENT_DETAILS_LOADING,
  paymentLoadingSuccess
} from "./Payment.state";
import { checkoutPaths, checkoutLocalStorageKey } from "./constants";
import {
  getEmailAddress,
  getPhoneNumber,
  getLineItemsForPaymentSubmission,
  getFeesTotal,
  getBillingAddress,
  getPayerName,
  getSavedCCField,
  getSavedACHField,
  getSubClientSlugFromProfile,
  getSubClientSlugFromPayment,
  getWallet,
  getWalletFromLocalStorage,
  getExpiredSession,
  getProfilePaymentStatus,
  getProfilePaymentStatusFromStorage,
  getIsGuestCheckout,
  getVisitIdFromState,
  getCartId
} from "./Payment.selectors";
import {
  createStoredInstrumentPaymentAttempt,
  createKeyedPaymentAttempt,
  createBankPaymentAttempt,
  getInvoice,
  getVisitId
} from "./graphql/Queries";
import {
  NEW_CC,
  NEW_ACH,
  SAVED_CC,
  SAVED_ACH,
  alertBarAction
} from "./Payment.state";
import {
  addAlert,
  clearAlerts
} from "../../../../components/alert-bar/AlertBar.state";
import { isNotEmpty } from "../../../../util/general";
import { toUpper } from "sanctuary";
import { isEmpty } from "ramda";
import { combineEpics } from "redux-observable";
import {
  getAccessToken,
  getClientSlug,
  getGraphqlServiceEndpoint
} from "../../../../util/state";
import { _zipFormat } from "../../../../util/formats";
import {
  clearMultiCart,
  clearShoppingCart
} from "../multi-cart/state/ShoppingCart.state";
import { getConstituentID } from "../../../profile/pages/user-profile/Profile.selectors";
import { refreshTransactionHistory } from "../../../profile/pages/user-profile/Profile.state";
import { checkoutLandingPage } from "../../../../util/router-utils";
import { clearLocalStorageAndRedirect } from "../../../../state/reducer";

const {
  SEND_PROFILE_PAYMENT,
  FETCH_RESOURCES_SUCCESS,
  refreshObligations,
  CREDIT_CARD_RESOURCE,
  ACH_RESOURCE,
  ADDRESS_SETTING,
  PHONE_SETTING,
  EMAIL_SETTING,
  SUBMIT_OPERATIONS,
  submitChange
} = profileState;

const getCommonHeaders = state => ({
  endpoint: getGraphqlServiceEndpoint(state),
  clientSlug: getClientSlug(state),
  authToken: getAccessToken(state)
});

const createPaymentFromLocalStorageEpic = (action$, state$) =>
  action$.ofType(CREATE_PAYMENT_FROM_LOCAL_STORAGE).pipe(
    RxOp.filter(({ payload }) => isNotEmpty(payload)),
    RxOp.mergeMap(({ payload }) => {
      const currentLocation = window.location;
      const checkoutTestPaths = checkoutPaths.map(path => ({ path }));
      const isCheckoutPath =
        matchRoutes(checkoutTestPaths, currentLocation) !== null;
      const isExpiredSession = getExpiredSession(state$.value);
      const { serviceName, invoiceId } = payload;
      if (isCheckoutPath && isExpiredSession) {
        return Rx.of(
          createPayment({ ...payload, isExpiredSession }),
          navigateToCheckoutSubpage({
            serviceName,
            invoiceId,
            subpage: "expired-session"
          })
        );
      }
      if (isCheckoutPath) {
        return Rx.of(createPayment(payload));
      }
      localStorage.removeItem(checkoutLocalStorageKey);
      return Rx.empty();
    })
  );

const expireCheckoutSessionEpic = (action$, state$) =>
  action$.ofType(INITIATE_EXPIRED_SESSION).pipe(
    RxOp.flatMap(() => {
      const paymentState = state$.value.checkout.payment;
      const {
        returnURL,
        cancelURL,
        serviceName,
        isProfilePayment,
        invoiceId
      } = paymentState;
      const defaultURL = isProfilePayment
        ? "/profile"
        : `/service/${serviceName}`;
      const defaultLabel = "Return";
      const expiredURL = returnURL ||
        cancelURL || { url: defaultURL, label: defaultLabel };
      return Rx.of(
        expirePaymentSession(),
        clearMultiCart({ cartId: getCartId(state$.value) }),
        clearShoppingCart(),
        setExpiredURL(expiredURL),
        navigateToCheckoutSubpage({
          serviceName,
          invoiceId,
          subpage: "expired-session"
        })
      );
    })
  );

const renderLandingPage = (state, partialPaymentAllowed) => {
  // short-circuit this if expired session is true to prevent weird navigation flash
  const isExpiredSession = getExpiredSession(state);
  if (isExpiredSession) {
    return "expired-session";
  }

  const wallet = getWallet(state) || getWalletFromLocalStorage(state);
  const profilePaymentStatus =
    getProfilePaymentStatus(state) || getProfilePaymentStatusFromStorage(state);

  return checkoutLandingPage({
    walletEnabled: wallet,
    isProfilePayment: !!profilePaymentStatus
  });
};

const createPaymentEpic = (action$, state$) =>
  action$.ofType(CREATE_PAYMENT, SEND_PROFILE_PAYMENT).pipe(
    RxOp.flatMap(
      ({ payload: { serviceName, invoiceId, partialPaymentAllowed } }) =>
        Rx.of(
          alertBarAction(clearAlerts()),
          navigateToCheckoutSubpage({
            serviceName,
            invoiceId,
            subpage: renderLandingPage(state$.value, partialPaymentAllowed)
          })
        )
    )
  );

// required adapter layer at the moment because internally we call things ACH
// and CreditCard while the backend is beginning a naming transition to Bank and
// Card. Once we update workflows and payment.state to match this naming change,
// this adapter can be removed
// -BW
const invoiceToPayment = ({
  invoiceId,
  invoice: {
    lineItems,
    customAttributes,
    accountId,
    fees,
    routingKey,
    subClientSlug,
    terms,
    contact,
    returnUrl,
    cancelUrl,
    allowedPaymentMethods,
    partialPaymentAllowed,
    paymentMinimumInCents,
    paymentMaximumInCents,
    accountLookupConfigKey,
    configForPaymentTypes,
    status
  }
}) => ({
  invoiceId,
  lineItems,
  customAttributes,
  accountId,
  routingKey,
  subClientSlug,
  allowedPaymentMethods: allowedPaymentMethods.map(s => s.toLowerCase()),
  returnURL: returnUrl,
  cancelURL: cancelUrl,
  fees: {
    ach: fees.bank.map(f => ({ ...f, type: f.kind })),
    creditCard: fees.card.map(f => ({ ...f, type: f.kind }))
  },
  terms: {
    ach: terms.bank,
    creditCard: terms.card
  },
  configForPaymentTypes: {
    ach: configForPaymentTypes?.bank ?? {},
    creditCard: configForPaymentTypes?.card ?? {}
  },
  contact: {
    agencyEmail: contact.email,
    agencyPhone: contact.phone,
    agencyPhoneLabel: contact.phoneLabel
  },
  partialPaymentAllowed,
  paymentMinimumInCents,
  paymentMaximumInCents,
  accountLookupConfigKey,
  status
});

const getVisitIdEpic = (action$, state$) =>
  action$.ofType(GET_VISIT_ID).pipe(
    RxOp.flatMap(() =>
      Rx.from(
        getVisitId({
          ...getCommonHeaders(state$.value),
          client:
            state$.value?.checkout?.payment?.accountLookupConfigKey ??
            selectSubClientSlug(state$.value),
          accountId: state$.value?.checkout?.payment?.accountId
        })
      ).pipe(
        RxOp.flatMap(({ obligations }) =>
          Rx.of(visitIdSuccess(obligations?.visitId))
        )
      )
    ),
    RxOp.catchError(err => Rx.of(visitIdFailure(err)))
  );

// Displays an artificially delayed loading spinner for payment amount or
// payment details page when payment method changes.
const loadPaymentEpic = action$ =>
  action$
    .ofType(PAYMENT_AMOUNT_LOADING, PAYMENT_DETAILS_LOADING)
    .pipe(
      RxOp.flatMap(() => Rx.of(paymentLoadingSuccess()).pipe(RxOp.delay(1000)))
    );

const fetchInvoiceEpic = (action$, state$) =>
  action$.ofType(FETCH_INVOICE).pipe(
    RxOp.flatMap(({ payload: { invoiceId } }) =>
      Rx.from(
        getInvoice({ ...getCommonHeaders(state$.value), invoiceId })
      ).pipe(
        RxOp.flatMap(({ getInvoice }) =>
          Rx.of(
            createPayment(invoiceToPayment({ invoiceId, invoice: getInvoice })),
            fetchInvoiceSuccess()
          )
        )
      )
    ),
    RxOp.catchError(err => Rx.of(fetchInvoiceFailure(err), push("/not-found")))
  );

const _paymentState = ["checkout", "payment"];
const _formsState = ["checkout", "forms"];

const createFormFieldGetter = (state, formKey) => fieldName =>
  get([..._formsState, formKey, fieldName], state);

const getAddressParams = state => {
  const billingAddress = getBillingAddress(state);
  const isNew = state.checkout.payment.newAddressSelected;
  return isNew
    ? {
        address: `${billingAddress.street1.rawValue}${
          billingAddress.street2.rawValue ? " , " : ""
        }${billingAddress.street2.rawValue || ""}`,
        city: billingAddress.city.rawValue,
        state: billingAddress.stateProvince.rawValue,
        country: billingAddress.country.rawValue,
        zip:
          billingAddress.country.rawValue === "US"
            ? _zipFormat(billingAddress.zip.rawValue.toUpperCase())
            : billingAddress.zip.rawValue.toUpperCase()
      }
    : {
        address: `${billingAddress.street1} ${
          billingAddress.street2 ? " , " : ""
        }${billingAddress.street2 || ""}`,
        city: billingAddress.city,
        state: billingAddress.stateProvince,
        country: billingAddress.country,
        zip: billingAddress.zip
      };
};

const filterEmptyCustomAttributes = customAttributes =>
  customAttributes.filter(item => !!item.value && item);

const getCommonParams = state => ({
  ...getCommonHeaders(state),
  traceNumber: get([..._paymentState, "traceNumber"], state),
  serviceFee: getFeesTotal(state),
  items: getLineItemsForPaymentSubmission(state),
  customAttributes: filterEmptyCustomAttributes(
    get([..._paymentState, "customAttributes"], state)
  ),
  accountId: get([..._paymentState, "accountId"], state),
  routingKey: get([..._paymentState, "routingKey"], state),
  captchaToken: createFormFieldGetter(state, "captchaForm")("captchaToken")
    .rawValue,
  payer: {
    ...getAddressParams(state),
    name: getPayerName(state),
    email: getEmailAddress(state),
    phone: getPhoneNumber(state),
    constituentId: getIsGuestCheckout(state) ? null : getConstituentID(state)
  },
  subClientSlug: selectSubClientSlug(state),
  paymentInitiator: getPaymentInitiator(state),
  visitId: getVisitIdFromState(state)
    ? parseInt(getVisitIdFromState(state))
    : undefined,
  invoiceId: get([..._paymentState, "invoiceId"], state)
});
const selectSubClientSlug = state =>
  getSubClientSlugFromPayment(state) || getSubClientSlugFromProfile(state);

/*
  Returns the enum value for the service or application that initiated the payment.
  These values must correspond to the PaymentInitiator enum in ghenghis.
*/
const getPaymentInitiator = state => {
  const isProfilePayment = getProfilePaymentStatus(state);
  const isGuestCheckout = getIsGuestCheckout(state);
  const constituentId = getConstituentID(state);
  const walletEnabled = getWallet(state);
  if (isProfilePayment) {
    return PAYMENT_INITIATOR_PROFILE;
  } else if (walletEnabled && constituentId && !isGuestCheckout) {
    return PAYMENT_INITIATOR_WALLET;
  } else {
    return PAYMENT_INITIATOR_WEB_CHECKOUT;
  }
};

const getSubmitSavedAchPaymentParams = state => {
  const getACHField = getSavedACHField(state);
  return {
    ...getCommonParams(state),
    payingWith: profileState.BANK_ACCOUNT_KIND,
    paymentInstrumentId: getACHField("id")
  };
};

const getSubmitSavedCreditCardPaymentParams = state => {
  const getCCField = getSavedCCField(state);
  return {
    ...getCommonParams(state),
    payingWith: profileState.CREDIT_CARD_KIND,
    paymentInstrumentId: getCCField("id")
  };
};

const getSubmitNewBankPaymentParams = state => {
  const achFormGetter = createFormFieldGetter(state, "achForm");
  const getACHRawField = fieldName => achFormGetter(fieldName).rawValue;
  return {
    ...getCommonParams(state),
    accountType: toUpper(getACHRawField("accountType")),
    routingNumber: getACHRawField("routingNumber"),
    accountNumber: getACHRawField("accountNumber")
  };
};

const getSubmitNewCreditCardPaymentParams = state => {
  const ccFormGetter = createFormFieldGetter(state, "creditCardForm");
  const getCCRawField = fieldName => ccFormGetter(fieldName).rawValue;
  return {
    ...getCommonParams(state),
    cvv: getCCRawField("cvv"),
    expirationMonth: get(slice(0, 2), getCCRawField("expirationDate")).join(""),
    expirationYear: get(slice(2, 4), getCCRawField("expirationDate")).join(""),
    cardNumber: getCCRawField("creditCardNumber")
  };
};

const handlerMethodTypeMap = {
  [SAVED_CC]: state =>
    createStoredInstrumentPaymentAttempt(
      getSubmitSavedCreditCardPaymentParams(state)
    ),
  [SAVED_ACH]: state =>
    createStoredInstrumentPaymentAttempt(getSubmitSavedAchPaymentParams(state)),
  [NEW_CC]: state =>
    createKeyedPaymentAttempt(getSubmitNewCreditCardPaymentParams(state)),
  [NEW_ACH]: state =>
    createBankPaymentAttempt(getSubmitNewBankPaymentParams(state))
};

const responseKeyMap = {
  [SAVED_CC]: "createStoredInstrumentPaymentAttempt",
  [SAVED_ACH]: "createStoredInstrumentPaymentAttempt",
  [NEW_CC]: "createKeyedCardPaymentAttempt",
  [NEW_ACH]: "createBankPaymentAttempt"
};

// Payment errors as defined in Ghenghis:
// ghenghis/app/interactors/payment_interactor/errors.rb
const ERROR_VISIT_EXPIRED = "1";
const ERROR_VELOCITY_CONTROL = "2";
const ERROR_INVALID_BANK_ACCOUNT = "3";
const ERROR_DUPLICATED = "4";
const ERROR_INVALID_CARD = "5";
const ERROR_AUTH_FAILED = "6";
const ERROR_HAS_CHARGEBACK = "7";
const ERROR_FORTE_CHECK_VERIFICATION_REJECTION = "8";

const DEFAULT_PAYMENT_ERROR_MESSAGE =
  "Unable to process payment. Please check your payment information and try again.";
const DEFAULT_PAYMENT_ERROR_HEADING = "Payment Failed";

const saveResourcesToWallet = newWalletData => {
  const walletMap = {
    cardPayment: CREDIT_CARD_RESOURCE,
    bankPayment: ACH_RESOURCE,
    address: ADDRESS_SETTING,
    phone: PHONE_SETTING,
    email: EMAIL_SETTING
  };

  return newWalletData.map(item => walletMap[item]);
};

const getNewWalletData = state => {
  const { savedWalletData } = state.checkout.wallet;
  const newWalletData = Object.entries(savedWalletData).reduce(
    (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
    {}
  );
  return Object.keys(newWalletData);
};

const sendWalletResourcesEpic = (action$, state$) =>
  action$.ofType(SEND_WALLET_RESOURCES).pipe(
    RxOp.flatMap(() =>
      Rx.from(saveResourcesToWallet(getNewWalletData(state$.value))).pipe(
        RxOp.concatMap(resource =>
          Rx.of(
            submitChange(resource, SUBMIT_OPERATIONS.ADD, "", {
              inWallet: true
            })
          )
        )
      )
    )
  );

const getSelectedType = state =>
  state.checkout.payment.selectedPaymentMethodType;
const submitPaymentEpic = (action$, state$) =>
  action$.ofType(SUBMIT_PAYMENT).pipe(
    RxOp.mergeMap(() =>
      Rx.from(
        handlerMethodTypeMap[getSelectedType(state$.value)](state$.value)
      ).pipe(
        RxOp.flatMap(
          ({
            [responseKeyMap[getSelectedType(state$.value)]]: {
              id,
              authCode,
              serviceFeePaymentAuthCode,
              immediatelyRedirectToUrl
            }
          }) =>
            Rx.of(
              alertBarAction(clearAlerts()),
              submitPaymentSuccess(
                id,
                authCode,
                serviceFeePaymentAuthCode,
                !!immediatelyRedirectToUrl
              ),
              refreshObligations(),
              refreshTransactionHistory(),
              clearProcessedPaymentInfo(),
              clearMultiCart({ cartId: getCartId(state$.value) }),
              clearShoppingCart(),
              !isEmpty(getNewWalletData(state$.value)) && sendWalletResources(),
              immediatelyRedirectToUrl &&
                clearLocalStorageAndRedirect({
                  url: immediatelyRedirectToUrl
                })
            )
        ),
        RxOp.catchError(({ response }) => {
          if (responseHasError(response, ERROR_DUPLICATED)) {
            return Rx.of(
              alertBarAction(clearAlerts()),
              submitPaymentDuplicate()
            );
          } else if (
            responseHasError(response, ERROR_FORTE_CHECK_VERIFICATION_REJECTION)
          ) {
            return Rx.of(
              alertBarAction(clearAlerts()),
              alertBarAction(
                addAlert({
                  heading: DEFAULT_PAYMENT_ERROR_HEADING,
                  text: responseMessageForError(
                    response,
                    ERROR_FORTE_CHECK_VERIFICATION_REJECTION
                  ),
                  variant: "error",
                  showQuitLink: false
                })
              ),
              submitPaymentFailure()
            );
          } else {
            return Rx.of(
              alertBarAction(clearAlerts()),
              alertBarAction(
                addAlert({
                  heading: DEFAULT_PAYMENT_ERROR_HEADING,
                  text: DEFAULT_PAYMENT_ERROR_MESSAGE,
                  variant: "error",
                  showQuitLink: false
                })
              ),
              submitPaymentFailure()
            );
          }
        })
      )
    )
  );

const responseHasError = (response, error) => {
  return (
    response?.errors?.some(err => err?.details?.error_code?.includes(error)) ??
    false
  );
};

const responseMessageForError = (response, error) => {
  const maybeError = response?.errors?.find(err => {
    return err?.details?.error_code?.includes(error);
  });

  return maybeError?.message ?? DEFAULT_PAYMENT_ERROR_MESSAGE;
};

const receiveProfileResourcesEpic = action$ =>
  action$
    .ofType(FETCH_RESOURCES_SUCCESS)
    .pipe(RxOp.map(a => receiveProfileResources(a.payload.data)));

const PaymentEpic = combineEpics(
  receiveProfileResourcesEpic,
  createPaymentEpic,
  fetchInvoiceEpic,
  submitPaymentEpic,
  createPaymentFromLocalStorageEpic,
  sendWalletResourcesEpic,
  expireCheckoutSessionEpic,
  getVisitIdEpic,
  loadPaymentEpic
);
export default PaymentEpic;
