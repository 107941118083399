import React, { useContext, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { isEmpty } from "ramda";
import {
  Box,
  Stack,
  Switcher,
  Paragraph,
  cardRegistry,
  Loading,
  Timeout,
  withWindowSize,
  WelcomeModule,
  constants
} from "@thecb/components";
import Redirect from "../../../../../common/pages/redirect";

const LOADING = "loading";
const SUCCESS = "success";
const TIMEOUT = "timeout";
const NOT_FOUND = "not found";

const Welcome = ({
  profileRouting,
  resources,
  welcomeCards,
  walletEnabled,
  obligationAssociations,
  onLogout
}) => {
  const { state } = useLocation();
  const { prevPage } = state?.options ?? "";
  const { pageType } = state ?? "";
  const handlePreviousPage = prevPage === "login" || pageType === "redirect";
  const { isMobile } = useContext(ThemeContext);
  const { obligations } = resources;
  const hasActiveAccounts = !isEmpty(
    obligationAssociations?.accounts?.active ?? {}
  );
  //const hasInactiveAccounts = !isEmpty(obligationAssociations?.accounts?.inactive ?? {});
  const hasActiveProperties = !isEmpty(
    obligationAssociations?.properties?.active ?? {}
  );
  //const hasInactiveProperties = !isEmpty(obligationAssociations?.properties?.inactive ?? {});
  const hasActiveData = hasActiveAccounts || hasActiveProperties;
  //const hasInactiveData = hasInactiveAccounts || hasInactiveProperties

  const cardVariant =
    isMobile || hasActiveAccounts || hasActiveProperties
      ? "horizontal"
      : "vertical";

  // Check to see if welcomeCards array is empty, if it is, use
  // profileRouting to get all the configured routes. Then
  // check if user has an account or property obligation with
  // configured routes, if so, we filter it out.
  const checkCard = card =>
    (card === "accounts" && !hasActiveAccounts) ||
    (card === "properties" && !hasActiveProperties) ||
    card === "payment";

  const reduceCards = cardList =>
    cardList.reduce(
      (acc, curr) => (checkCard(curr) ? [...acc, curr] : acc),
      []
    );

  const configureCards = () => {
    const cards = !isEmpty(welcomeCards)
      ? reduceCards(welcomeCards)
      : [...reduceCards(profileRouting), "payment"];

    return cards.map(card =>
      cardRegistry[card]({
        variant: cards.length === 1 ? "horizontal" : cardVariant,
        key: card
      })
    );
  };

  const getStatus = obligations =>
    obligations.isLoading
      ? LOADING
      : obligations.isSuccess
      ? SUCCESS
      : obligations.isFailure && obligations.error === "TIMEOUT"
      ? TIMEOUT
      : NOT_FOUND;

  const redirectPage = () =>
    profileRouting.includes("accounts") ? (
      <Redirect redirectTo="/profile/accounts" />
    ) : (
      <Redirect redirectTo="/profile/properties" />
    );
  const renderCards = () => (
    <Box
      padding="0"
      background={constants.colors.WHITE}
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
0px 3px 8px 0px rgb(202, 206, 216)"
      extraStyles={
        isMobile
          ? `width: 100%; margin-top: -24px;`
          : `display: inline-flex; flex-direction: column;`
      }
    >
      <WelcomeModule heading="Welcome!" textAlign="left" isMobile={isMobile} />
      <Box padding={isMobile ? "1.5rem 1rem" : "1.5rem"}>
        <Stack childGap="1.5rem" background={constants.colors.WHITE}>
          <Box padding="0" extraStyles={`display: flex;`}>
            <Box padding="0" extraStyles={`flex-grow: 1; width: 0;`}>
              <Paragraph variant="pL">
                Pay your bills quickly. Manage your personal data. Get started
                below.
              </Paragraph>
            </Box>
          </Box>
          <Switcher
            breakpoint="42rem"
            extraStyles={!isMobile && `flex-wrap: nowrap;`}
          >
            {configureCards()}
          </Switcher>
        </Stack>
      </Box>
    </Box>
  );

  const renderPage = obligations => {
    const status = getStatus(obligations);
    if (status === NOT_FOUND && walletEnabled) {
      return <Fragment>{renderCards()}</Fragment>;
    }
    switch (status) {
      case LOADING:
        return <Loading />;
      case TIMEOUT:
        return <Timeout onLogout={onLogout} />;
      case SUCCESS:
        return (
          <Fragment>
            {hasActiveData && handlePreviousPage ? (
              <Fragment>{redirectPage()}</Fragment>
            ) : (
              <Fragment>{renderCards()}</Fragment>
            )}
          </Fragment>
        );
      default:
        return null;
    }
  };

  return <Fragment>{renderPage(obligations)}</Fragment>;
};

export default withWindowSize(Welcome);
