import React, { useEffect } from "react";
import { required, hasLength } from "redux-freeform";
import { createFormat } from "formatted-input";
import {
  FormInput,
  FormContainer,
  FormInputColumn
} from "../../atoms/form-layouts";
import { noop } from "../../../util/general";
import Checkbox from "../../atoms/checkbox";
import { phoneFormats, formatDelimiter } from "../../../util/formats";

const PhoneForm = ({
  variant = "default",
  fields,
  actions,
  clearOnDismount,
  showErrors,
  handleSubmit = noop,
  showWalletCheckbox,
  saveToWallet,
  walletCheckboxMarked,
  isRequired = false
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const phoneErrorMessage = {
    [required.error]: "Phone number is required",
    [hasLength.error]: "Phone number must be 10 digits"
  };

  return (
    <FormContainer variant={variant} role="form" aria-label="Phone number">
      <FormInputColumn>
        <FormInput
          labelTextWhenNoError="Phone number"
          errorMessages={phoneErrorMessage}
          field={fields.phone}
          fieldActions={actions.fields.phone}
          showErrors={showErrors}
          formatter={createFormat(phoneFormats, formatDelimiter)}
          onKeyUp={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="tel-national"
          dataQa="Phone number"
          isNum={true}
          isRequired={isRequired}
        />
        {showWalletCheckbox && (
          <Checkbox
            name="phone checkbox"
            title="Save phone number to wallet"
            checked={walletCheckboxMarked}
            onChange={saveToWallet}
          />
        )}
      </FormInputColumn>
    </FormContainer>
  );
};

export default PhoneForm;
