import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const CarrotIcon = ({ themeValues }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="10"
      height="5"
      viewBox="0 0 10 5"
    >
      <defs>
        <path
          id="carrot-path-1"
          d="M6.99999958 9.9999994L11.9999993 14.9999991 16.999999 9.9999994z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-423 -974)">
          <g transform="translate(372 958)">
            <g transform="translate(44 6)">
              <mask id="carrot-mask-2" fill="#fff">
                <use xlinkHref="#carrot-path-1"></use>
              </mask>
              <use fill="#000" xlinkHref="#carrot-path-1"></use>
              <g
                fill={themeValues.singleIconColor}
                fillRule="nonzero"
                mask="url(#carrot-mask-2)"
              >
                <g>
                  <path d="M0 0H24V24H0z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default themeComponent(CarrotIcon, "Icons", fallbackValues, "darkMode");
