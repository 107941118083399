import {
  WHITE,
  ATHENS_GREY,
  MANATEE_GREY,
  MATISSE_BLUE
} from "../../../constants/colors";

const onBackground = `${MATISSE_BLUE}`;
const disabledBackground = `${MANATEE_GREY}`;
const disabledBackgroundLight = `${ATHENS_GREY}`;
const white = `${WHITE}`;

const labelStyles = `
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const rightLabelStyles = `
  ${labelStyles}
  flex-direction: row;
`;

const leftLabelStyles = `
  ${labelStyles}
  flex-direction: row-reverse;
`;

export const fallbackValues = {
  onBackground,
  disabledBackground,
  disabledBackgroundLight,
  white,
  rightLabelStyles,
  leftLabelStyles
};
