import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../dropdown";
import Text from "../text";
import { ERROR_COLOR } from "../../../constants/colors";
import { SelectContainer } from "./FormSelect.styled";
import { fallbackValues } from "./FormSelect.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Box, Cluster, Stack } from "../layouts";
import { createIdFromString } from "../../../util/general";

const FormSelect = ({
  fieldActions,
  labelTextWhenNoError,
  errorMessages,
  options,
  field,
  showErrors,
  onChange,
  dropdownMaxHeight,
  disabledValues,
  disabled,
  themeValues,
  hasTitles = false,
  autocompleteValue, // browser autofill value, like country-name
  smoothScroll = true, // whether the browser should animate scroll to selected item on first open
  dataQa = null,
  widthFitOptions = false,
  isRequired = false
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickAway = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  });

  return (
    <SelectContainer
      ref={dropdownRef}
      disabled={disabled}
      aria-disabled={disabled}
      data-qa={dataQa}
    >
      <Box padding="0" minWidth="100%">
        <Cluster justify="space-between" align="center">
          <Text
            as="label"
            variant="pS"
            color={themeValues.labelColor}
            weight={themeValues.fontWeight}
            extraStyles={`word-break: break-word;
              font-family: Public Sans;
              &::first-letter {
                text-transform: uppercase;
              }`}
            id={createIdFromString(labelTextWhenNoError)}
          >
            {labelTextWhenNoError}
          </Text>
        </Cluster>
      </Box>
      <Dropdown
        ariaLabelledby={createIdFromString(labelTextWhenNoError)}
        ariaDescribedby={createIdFromString(
          labelTextWhenNoError,
          "error message"
        )}
        maxHeight={dropdownMaxHeight}
        widthFitOptions={widthFitOptions}
        hasTitles={hasTitles}
        placeholder={options[0] ? options[0].text : ""}
        options={options}
        value={field.rawValue}
        disabledValues={disabledValues}
        isOpen={open}
        isError={
          (field.hasErrors && field.dirty) || (field.hasErrors && showErrors)
        }
        ariaInvalid={
          (field.hasErrors && field.dirty) || (field.hasErrors && showErrors)
        }
        onSelect={
          onChange ? value => onChange(value) : value => fieldActions.set(value)
        }
        onClick={() => setOpen(!open)}
        disabled={disabled}
        autocompleteValue={autocompleteValue}
        smoothScroll={smoothScroll}
        isRequired={isRequired}
      />
      <Stack direction="row" justify="space-between">
        {(field.hasErrors && field.dirty) || (field.hasErrors && showErrors) ? (
          <Text
            color={ERROR_COLOR}
            variant="pXS"
            weight={themeValues.fontWeight}
            extraStyles={`
            word-break: break-word;
            font-family: Public Sans;
            &::first-letter {
              text-transform: uppercase;
            }
          `}
            id={createIdFromString(labelTextWhenNoError, "error message")}
            aria-live="polite"
            aria-atomic={true}
            data-qa={createIdFromString(labelTextWhenNoError, "error message")}
          >
            {errorMessages[field.errors[0]]}
          </Text>
        ) : (
          <Text extraStyles={`height: ${themeValues.lineHeight};`} />
        )}
      </Stack>
    </SelectContainer>
  );
};

export default themeComponent(
  FormSelect,
  "FormSelect",
  fallbackValues,
  "default"
);
