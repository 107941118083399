import { INFO_BLUE, STORM_GREY, WHITE } from "../../../constants/colors";

const backgroundColor = WHITE;
const imageBackgroundColor = INFO_BLUE;

const headerBackgroundColor = STORM_GREY;
const headerColor = WHITE;

export const fallbackValues = {
  backgroundColor,
  imageBackgroundColor,
  headerBackgroundColor,
  headerColor
};
