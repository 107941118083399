import React from "react";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import { TitleText } from "./Title.styled";
import { safeChildren } from "../../../util/general";
import {
  fallbackValues,
  mobileFallbackValues,
  MOBILE_BREAKPOINT
} from "./Title.theme";
import { themeComponent } from "../../../util/themeUtils";

/*
  New responsive text component for Title elements
  Size is decoupled from tag
  Comes with two variants - "large" and "small"
  Large variant results in text that is 1.5rem (24px desktop, 21px mobile)
  Small variant results in text that is 1.25rem/1.2142rem (20px desktop, 17px mobile)
  
  If you need a smaller text size, use the <Detail /> atom, which has smaller variants
  
  Use the "as" prop to specify element type, can use any of: h1/h2/h3/h4/h5/h6/p
  If you need an inline text element (span), use the <Text /> atom
  The "as" value should be dictated by the structure of your page, not what font-size you want

  Title / Detail both use slightly different desktop/mobile scales
  (Mobile scale is different to allow for design-friendly px values)
  Both atoms detect the presence of a mobile device on their own
  and apply the corresponding scale

  Mobile breakpoint value is specified in Title.theme.js

  If you want to disable mobile text scales (use the same rem scale for desktop/mobile), then use a theme in FCS to set both the "large" and "small" variants to use the same rem sizes. FCS themes override fallbacks defined in the .theme file
*/

const isBelowBreakpoint = window.innerWidth < MOBILE_BREAKPOINT;
const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 1;
const mobileDeviceDetected = isBelowBreakpoint && isTouchDevice;
const fallbacks = mobileDeviceDetected ? mobileFallbackValues : fallbackValues;

const Title = ({
  themeValues,
  weight = FONT_WEIGHT_REGULAR,
  color = FIREFLY_GREY,
  margin = 0,
  textAlign,
  extraStyles = ``,
  className,
  variant = "large",
  as = "h1",
  dataQa,
  children,
  ...rest
}) => {
  return (
    <TitleText
      variant={variant}
      as={as}
      weight={weight}
      color={color}
      margin={margin}
      textAlign={textAlign}
      extraStyles={extraStyles}
      className={className}
      fontFamily={themeValues.fontFamily}
      fontSize={themeValues.fontSize}
      data-qa={dataQa}
      {...rest}
    >
      {safeChildren(children, <span />)}
    </TitleText>
  );
};

export default themeComponent(Title, "Title", fallbacks, "large");
