import React, { useContext, forwardRef } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "./Link.theme";
import { createThemeValues } from "../../../util/themeUtils";
import { StyledExternalLink } from "./ExternalLink.styled";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { safeChildren } from "../../../util/general";

const ExternalLink = forwardRef(
  (
    {
      href = "",
      newTab = false,
      size = "1rem",
      lineHeight = "1.5rem",
      weight = FONT_WEIGHT_REGULAR,
      extraStyles = ``,
      variant = "primary",
      tabIndex = "0",
      dataQa,
      ariaLabel,
      children
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);
    const themeValues = createThemeValues(
      themeContext,
      fallbackValues,
      "Link",
      variant
    );

    return (
      <StyledExternalLink
        href={href}
        target={newTab ? "_blank" : ""}
        color={themeValues.externalLinkColor}
        size={size}
        lineheight={lineHeight}
        weight={weight}
        hovercolor={themeValues.hoverColor}
        activeColor={themeValues.activeColor}
        fontFamily={themeValues.fontFamily}
        tabIndex={tabIndex}
        extrastyles={extraStyles}
        rel={newTab ? "noopener" : ""}
        data-qa={dataQa}
        aria-label={ariaLabel}
        ref={ref}
      >
        {safeChildren(children, <span />)}
      </StyledExternalLink>
    );
  }
);

export default ExternalLink;
