import React from "react";
import { Modal } from "@thecb/components";
import { formatDateObject } from "../../../../../../util/dateUtil.js";
import {
  getCustomAttribute,
  isInvoiceExpired,
  isInvoicePaid,
  isInvoicePaylink,
  isInvoiceCancelled
} from "../../../../../../util/paymentUtils.js";

/**
 * Generates a modal title describing an invalid invoice.
 * @param {object} payment
 * @returns a modal title
 */
const generateTitle = (payment = {}) => {
  const orderID = getCustomAttribute(payment, "order_id");
  if (orderID && isInvoicePaylink(payment)) {
    return `PayLink #${orderID} No Longer Valid`;
  }

  const invoiceID = getCustomAttribute(payment, "customer_invoice_id");
  if (invoiceID) {
    return `Invoice #${invoiceID} No Longer Valid`;
  }

  // This is the default value if the expected PayLink and Invoice values are
  // not present in the payment object for whatever reason.
  return "Invoice No Longer Valid";
};

/**
 * Generates modal body text describing an invalid invoice.
 * @param {object} payment
 * @returns modal body text
 */
const generateBodyText = (payment = {}) => {
  if (isInvoiceExpired(payment) || isInvoiceCancelled(payment)) {
    if (isInvoicePaylink(payment)) {
      return "Invoice has expired";
    } else {
      return "The invoice has expired. Please go back to check your balance.";
    }
  } else if (isInvoicePaid(payment)) {
    const date = formatDateObject(payment.paymentDate, "M/D/YY");
    const paymentMsg = date
      ? `A payment occurred on ${date}`
      : "A payment has already occurred";
    return `${paymentMsg}. Please go back to check your balance.`;
  }

  // This message should never appear under normal circumstances. It is here as a
  // backup just in case a new invoice status is added to the invoice status enum
  // but not accounted for in this modal.
  // See: https://github.com/CityBaseInc/graphql_service/blob/master/lib/api/schema/invoice_types.ex#L160-L163
  return `This invoice is no longer active. Please go back to check your balance.`;
};

/**
 * Displays a blocking modal that informs the user of an invalid invoice.
 * This modal can appear during standard checkout or after navigating to
 * a PayLink link.
 * @param {object} props - React component props
 * @param {object} props.payment - invoice payment object
 * @returns a modal displaying the status of an invalid invoice
 */
export const InvalidInvoiceStatusModal = ({ payment = {} }) => {
  const returnURL = payment.returnURL?.url || "https://eunasolutions.com/";

  return (
    <Modal
      continueButtonText="Go Back"
      continueURL={returnURL}
      modalBodyText={generateBodyText(payment)}
      modalHeaderText={generateTitle(payment)}
      modalOpen
      noButtons={isInvoicePaylink(payment)}
      onlyContinueButton
      underlayClickExits={false}
      version="v2"
    />
  );
};

// These private functions are exported for testing only.
export const __private__ = {
  generateBodyText,
  generateTitle
};

export default InvalidInvoiceStatusModal;
