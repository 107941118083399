import {
  createFormState,
  required,
  onlyIntegers,
  hasLength
} from "redux-freeform";

const formConfig = {
  phone: {
    validators: [required(), hasLength(10, 10)],
    constraints: [onlyIntegers(), hasLength(0, 10)]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
