import React from "react";
import { LoadingPill } from "./LoadingPill.styled";

const LoadingLine = ({
  minWidth,
  maxWidth,
  exactWidth = null,
  height = "16px",
  margin = "0px"
}) => (
  <LoadingPill
    margin={margin}
    height={height}
    width={
      exactWidth ||
      Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth
    }
  />
);

export default LoadingLine;
