import React from "react";

import { themeComponent } from "../../../util/themeUtils";
import withWindowSize from "../../withWindowSize";

import { fallbackValues } from "./CardText.theme";

import Box from "../layouts/Box";
import Cover from "../layouts/Cover";
import Paragraph from "../paragraph";
import Stack from "../layouts/Stack";
import Title from "../title";

export const CardText = ({
  padding,
  text,
  titleText,
  titleVariant = "small",
  themeValues
}) => {
  return (
    <Box padding={padding}>
      <Cover>
        <Stack>
          {titleText && (
            <Title
              as="p"
              variant={titleVariant}
              color={themeValues.titleColor}
              weight={themeValues.titleWeight}
            >
              {titleText}
            </Title>
          )}
          <Paragraph color={themeValues.textColor}>{text}</Paragraph>
        </Stack>
      </Cover>
    </Box>
  );
};

export default themeComponent(
  withWindowSize(CardText),
  "CardText",
  fallbackValues
);
