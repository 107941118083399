import React, { useContext } from "react";
import * as L from "partial.lenses";
import { ThemeContext } from "styled-components";
import { useNavigate } from "react-router-dom";
import { groupBy } from "ramda";
import {
  ArrowRightIcon,
  AutopayIcon,
  Badge,
  Box,
  Detail,
  Grid,
  LinkCard,
  PlusCircleIcon,
  Stack,
  Text,
  Title,
  util
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../../constants/style_constants";
import { formatName, noop } from "../../../../../../util/general";
import { FILTERED_FINDABLE_ACCOUNTS_RESOURCE } from "../../Profile.state";
import { FindAccountWrapper } from "./FindAccount.styled";
import {
  CORNFLOWER_BLUE,
  ROYAL_BLUE_VIVID,
  LINK_WATER,
  WHITE,
  MANATEE_GREY,
  SEA_GREEN,
  TRANSPARENT,
  GHOST_GREY
} from "../../../../../../constants/colors";
import { isInCustomerManagement } from "../../../../../../util/router-utils";

const reconstructedLinkCardFallbackValues = {
  color: ROYAL_BLUE_VIVID,
  borderColor: LINK_WATER,
  backgroundColor: WHITE,
  activeBackgroundColor: CORNFLOWER_BLUE,
  disabledColor: MANATEE_GREY,
  disabledBackgroundColor: TRANSPARENT,
  disabledBorderColor: GHOST_GREY
};

const ProfileFindAccount = ({ resources }) => {
  const TABLET_BREAKPOINT = 1048;
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const linkCardThemeValues = util.theme.createThemeValues(
    themeContext,
    reconstructedLinkCardFallbackValues,
    "LinkCard",
    "primary"
  );
  const navigate = useNavigate();
  const accounts = L.get(
    L.prop(FILTERED_FINDABLE_ACCOUNTS_RESOURCE),
    resources
  );
  const groupAccountsBySubClient = groupBy(account => {
    return account.sub_client;
  });

  const groupedAccounts = Object.entries(
    groupAccountsBySubClient(accounts)
  ).sort(([agencyNameA, _valueA], [agencyNameB, _valueB]) =>
    agencyNameA.localeCompare(agencyNameB)
  );

  const subClientAccounts = ([subClientName, accounts]) => {
    const accountValues = Object.values(accounts);
    return (
      <Box
        padding="1.5rem 0 0.5rem"
        dataQa={`findable-account-${subClientName}`}
        key={`findable-account-${subClientName}`}
      >
        <Stack childGap="0.5rem">
          {subClientName &&
            subClientName != "undefined" &&
            groupedAccounts.length > 1 && (
              <Detail as="h2" variant="regular">
                {formatName(subClientName)}
              </Detail>
            )}
          <Box padding="0">
            <Grid
              columnGap={"1.5rem"}
              minColWidth={
                /* % widths based on number of columns per row, 
                minus the column gap */
                isMobile
                  ? `calc(100% - 1.5rem)`
                  : !isMobile && window.innerWidth <= TABLET_BREAKPOINT
                  ? `calc(50% - 1.5rem)`
                  : `calc(33.33% - 1.5rem)`
              }
            >
              {accountValues.map(renderAccount)}
            </Grid>
          </Box>
        </Stack>
      </Box>
    );
  };

  const actionNameForWorkflowType = workflowType => {
    switch (workflowType) {
      case "default":
        return "Pay";
      case "obligation_association":
        return "Find";
      default:
        return "Find";
    }
  };

  const renderAccount = ({ title, subtitle, slug, workflow_type }) => {
    const workflowActionName = actionNameForWorkflowType(workflow_type);
    const isObligationAssociation = workflow_type === "obligation_association";

    return (
      <LinkCard
        key={slug}
        extraStyles={
          isInCustomerManagement ? `` : `transition: all .2s ease-in-out;`
        }
        extraHoverStyles={
          isInCustomerManagement
            ? `box-shadow: none; cursor: default; .show-on-hover {opacity: 0}`
            : `.show-on-hover {opacity: 1}`
        }
        title={title}
        subtitle={subtitle}
        slug={slug}
        onClick={
          isInCustomerManagement ? noop : () => navigate(`/service/${slug}`)
        }
        disabled={isInCustomerManagement}
        showLeft={true}
        leftContent={
          isObligationAssociation && (
            <Badge
              label="Autopay Available"
              variant={isInCustomerManagement ? "disabled" : "success"}
              Icon={() =>
                AutopayIcon({
                  fill: isInCustomerManagement ? MANATEE_GREY : SEA_GREEN
                })
              }
            />
          )
        }
        showRight={true}
        rightContent={
          <Box
            data-qa={`find-or-pay-${slug}`}
            extraStyles={`
              display: flex;
              gap: 6px;
              justify-content: space-between;
              align-items: center;
            `}
            padding="0"
            hoverStyles=".show-on-hover {opacity: 1;}"
          >
            <Text
              variant="pS"
              className="show-on-hover"
              color={
                isInCustomerManagement
                  ? linkCardThemeValues.disabledColor
                  : linkCardThemeValues.color
              }
              data-qa={slug}
              id={`workflow-${slug}`}
              extraStyles={`
                transition: opacity .2s ease-in-out; 
                opacity: ${isMobile ? "1" : "0"}
              `}
            >
              {workflowActionName}
            </Text>
            {isObligationAssociation ? (
              <PlusCircleIcon
                labelledBy={`workflow-${slug}`}
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={`find-${slug}`}
              />
            ) : (
              <ArrowRightIcon
                labelledBy={`workflow-${slug}`}
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={`pay-${slug}`}
              />
            )}
          </Box>
        }
      />
    );
  };

  return (
    <FindAccountWrapper>
      <Title
        variant="small"
        as="h1"
        weight={FONT_WEIGHT_SEMIBOLD}
        data-qa="findScreenTitle"
      >
        Find and Pay
      </Title>
      {groupedAccounts.map(subClientAccounts)}
    </FindAccountWrapper>
  );
};

export default ProfileFindAccount;
